export default class extends Controller {
  static targets = ["custom", "daily", "weekly", "muteOptions", "customWeekday", "notificationDiv", "activeNotification", "userNotificationForm"]

  connect(){
    this.checkSnoozeNotification()
    this.toggleNotificationDiv()
  }

  // Handler for mute options changes
  handleMuteOptionsChange() {
    const selectedValue = this.muteOptionsTarget.value;

    // Show/hide specific settings based on selection
    this.customTarget.classList.toggle('d-none', selectedValue !== 'custom');
    this.dailyTarget.classList.toggle('d-none', selectedValue !== 'daily');
    this.weeklyTarget.classList.toggle('d-none', selectedValue !== 'weekly');
    this.customWeekdayTarget.classList.toggle('d-none', selectedValue !== 'custom_weekday');
  }

  toggleNotificationDiv(){
    if (this.activeNotificationTarget.checked){
      this.notificationDivTarget.classList.remove('d-none');
    }else{
      this.notificationDivTarget.classList.add('d-none');
    }
  }

  submitForm(){
    this.userNotificationFormTarget.requestSubmit()
  }

  checkSnoozeNotification() {
    if (this.activeNotificationTarget.checked){
      fetch('/user_notification_preferences/check_snooze_notification', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            window.location.reload(); // Reload the page on success
          } else if (data.errors) {
            console.error('Errors:', data.errors); // Handle errors if needed
          }
        })
        .catch(error => {
          console.error('Request failed:', error); // Handle request failure
        });
    }
  }
}
